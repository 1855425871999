<template>
  <div>clear</div>
</template>

<script>
import { clearToken } from "../../common/js/cache"
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  created(){
      clearToken();
  },

  mounted() {},

  methods: {}
}

</script>
<style lang='scss' scoped>
</style>